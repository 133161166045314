import 'antd/dist/reset.css';
import React, { useEffect, useRef, useState } from 'react';
import { Space, Table, Alert, Button, Input, Switch, Popover, 
  Popconfirm, Row, Col, Card, Avatar, Form, Tag, Image, 
  DatePicker, Modal, ConfigProvider, Select  } from 'antd';
import { SearchOutlined, CheckCircleTwoTone, EyeOutlined, EyeInvisibleOutlined, UserOutlined, CalendarOutlined, FileOutlined } from '@ant-design/icons';
import Highlighter                            from 'react-highlight-words';
import moment                                 from "moment";
import dayjs                                  from "dayjs";
import locale from "antd/es/locale/es_ES";
import updateLocale from "dayjs/plugin/updateLocale";

import "dayjs/locale/es";
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ServicesHospital }       from "../services/ServicesHospital";

dayjs.extend(updateLocale);
dayjs.updateLocale("es", {
  weekStart: 1
});

const { TextArea } = Input;

const stateInitial = {
  openModalState: false,
  openNotesModalState: false,
  selectedRow: {},
  filters: {
    estado: false ,
    fecha: moment()
  }
}

/**
 * Component HospitalOperacion
 * @returns 
 */
export const HospitalOperacion = () => {

  ////////
  // Hooks
  ////////
  const [state, setState] = useState(stateInitial);
  const [cases, setCases] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [option, setOption] = useState(null);
  const [caseVisible, setCaseVisible] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [notas, setNotas] = useState('');
  const [errorOption, setErrorOption] = useState(false);
  const [optionsClientes, setOptionsClientes] = useState([
    {value:"PRIVADO", label:'Cliente Privado'},
    {value:"COMPANIA", label:'Compañía de seguros'},
    {value:"SCS", label:'Servicio Canario de Salud'},
    {value:"OTROS", label:'Otros'},
    {value:"NO_CONTESTA", label:'No contesta'}
  ]);
  
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleSearch = (
    selectedKeys, confirm, dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  
  const getColumnSearchProps = (dataIndex, dataText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex] 
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const case_columns = [
    {
      title: ' ',
      dataIndex: 'estado',
      key: 'actions',
      render: (value, all) => {
        return <>
          {value 
              ? (<>
                <Popover title="Gestionar">
                  <Button shape="circle" onClick={() => handleOpenModalState(all)} icon={<EyeInvisibleOutlined />} />
                </Popover>
                <Popover title="Modificar notas">
                  <Button l shape="circle" onClick={() => handleNotesModalState(all)} icon={<FileOutlined />} />
                </Popover>
                </>)

              : (<>
                <Button shape="circle" onClick={() => handleOpenModalState(all)} icon={<EyeOutlined />} />
                </>)
          }
          {all.cita && <CalendarOutlined style={{marginLeft:'5px'}}/>}
        </>
      }
    },
    {
      title: 'Fecha',
      dataIndex: 'inicio',
      key: 'inicio',
      sorter: {
        compare: (a, b) => moment(a.inicio) > moment(b.inicio) ? 1 : -1
      },
      sortOrder: 'descend',
      render: (value) => moment(new Date(value.substring(0,22))).format("DD/MM/YYYY HH:mm")
    },   
    {
      title: 'Teléfono',
      dataIndex: 'user_id',
      key: 'user_id',
      ...getColumnSearchProps('user_id','teléfono'),
    },
    {
      title: 'Transcripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps('descripcion','transcripción'),
    },
    {
      title: 'Menu',
      dataIndex: 'nombre',
      key: 'nombre',
      ...getColumnSearchProps('nombre','Menu'),
    },
    {
      title: 'Privado',
      dataIndex: 'privado',
      key: 'privado',
      render: (value, all) => {
        return <>
          {value && <CheckCircleTwoTone twoToneColor="#52c41a" />}
        </> 
      },
      filters: [
        {text: 'SI', value: true,},
        {text: 'NO', value: false},
      ],
      defaultFilteredValue: [true],
      onFilter: (value, record) => record.privado == value,
    },
    {
      title: 'Notas',
      dataIndex: 'notas',
      key: 'notas',
      ...getColumnSearchProps('notas','notas'),
    },
  ];

  useEffect(() => {
    // Update title
    document.title = "Icot - Hospital operación";

    getCases({ fecha: moment(), estado: true });
  }, []);

  /**
   * 
   * @param {object} values values.fecha of type moment, values.estado of type boolean
   */
  const getCases = async(values) => {
    setGenerate(false);
    setNotas("");
    setCases([]);
    setOption(null);
    ServicesHospital.getCases(values.estado ,values.fecha)
    .then((cases) => setCases(cases));      
  }

  const handleOpenModalState = (all) => {
    setNotas(all.notas);
    setState(prevState => ({
      ...prevState,
      openModalState: true,
      selectedRow: all
    }));
  }

  const handleNotesModalState = (all) => {
    setNotas(all.notas);
    setState(prevState => ({
      ...prevState,
      openNotesModalState: true,
      selectedRow: all
    }));
  }

  const handleCloseModalState = () => {
    setState(prevState => ({
      ...prevState,
      openModalState: false,
      openNotesModalState: false
    }));
    setNotas("");
    setOption(null);
    setErrorOption(false);
    setOptionsClientes ([
      {value:"PRIVADO", label:'Cliente Privado'},
      {value:"COMPANIA", label:'Compañía de seguros'},
      {value:"SCS", label:'Servicio Canario de Salud'},
      {value:"OTROS", label:'Otros'},
      {value:"NO_CONTESTA", label:'No contesta'}
    ]);
    setGenerate(false);
  }


  const changeStateWithPhone = (all) => {
    if(!option){
      setErrorOption(true);
      return;
    }
    setErrorOption(false);
    ServicesHospital.changeStateWithPhone(
      all.user_id, moment(all.inicio), all.estado, generate, notas, option)
    .then((response) => {
      getCases({ fecha: state.filters.fecha, estado: state.filters.estado  });
      handleCloseModalState();
    });
  }

  const changeNotesWithPhone = (all) => {
    ServicesHospital.changNotesWithId(all.id, notas)
    .then((response) => {
      getCases({ fecha: state.filters.fecha, estado: state.filters.estado  });
      handleCloseModalState();
    });
  }

  const changeOptionsClientes=()=>{
    if(generate===true){
      setOptionsClientes ([
        {value:"PRIVADO", label:'Cliente Privado'},
        {value:"COMPANIA", label:'Compañía de seguros'},
        {value:"SCS", label:'Servicio Canario de Salud'},
        {value:"OTROS", label:'Otros'},
        {value:"NO_CONTESTA", label:'No contesta'}
      ])
    }else{
      setOptionsClientes ([
        {value:"PRIVADO", label:'Cliente Privado'},
        {value:"COMPANIA", label:'Compañía de seguros'}
      ])
    }
    setOption(null);
    setGenerate(!generate);
  }

  return (
  <ConfigProvider locale={locale}>
    <Space direction="vertical" size="middle" style={{ display: 'flex', margin:'10px' }}>
      {caseVisible && <Alert type="info" message={<b>Llamadas</b>} closable afterClose={() => setCaseVisible(false)}
        description={
          <>
            <p>Transcripción de llamadas recibidas en la centralita del hospital.</p>
          </>
        } />}
      <Row>
        <Col span={20}>
          <Space wrap>
            <Form 
              name="filters"
              layout='horizontal'
              // We use library dayjs only to this special and rare case
              // due to Ant Design
              initialValues={{ estado: true, fecha: dayjs() }}
              onFinish={(values) => {
                setState(prevState => ({ 
                  ...prevState,
                  filters: {
                    ...values,
                  }
                }));
                getCases(values);
              }}
            >
              <Space style={{ display: 'flex', marginTop: 8 }} >
                <Form.Item label="Fecha" name="fecha"
                  rules={[{ required: true, message:"Fecha de llamada requerida", type: 'object' }]}
                >
                  <DatePicker format={"DD/MM/YYYY"}/>
                </Form.Item>
                <Form.Item name="estado">
                </Form.Item>
                <Form.Item >
                  <Button type="primary" htmlType="submit" style={{marginLeft:'25px'}}>
                    Filtrar
                  </Button>
                </Form.Item>
                </Space>
            </Form>
            
          </Space>
        </Col>
        <Col span={4} style={{textAlign:'end'}}>
          {!caseVisible && <Button  type="link" onClick={() => {setCaseVisible(true)}}>Info</Button>}
        </Col>
      </Row>
    </Space>

    <div style={{ backgroundColor: "#FFF", overflow: "auto" }}>
      <Table dataSource={cases} columns={case_columns} 
          bordered pagination={false}/>
    </div>

    {/* Modals */}
    {/* Modal to show 3 options */}
    <Modal
      title="Opciones"
      open={state.openModalState}
      onCancel={handleCloseModalState}
      footer={[
        <Button onClick={handleCloseModalState}>
          Cancelar
        </Button>,
        <Button type="primary" onClick={() => changeStateWithPhone(state.selectedRow)}>
          Aceptar
        </Button>,
      ]}
    >
      <Row>
        <Col span={3}>
          <ExclamationCircleOutlined style={{ fontSize: '30px', color: '#FAAD14' }}/>
        </Col>
        <Col span={21}>
          <Row>
            <Col span={24}>
              ¿Quiere marcar la llamada como gestionada? 
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <div style={{marginTop:'6px'}}>Indique si la llamada ha generado una cita</div>
            </Col>
            <Col span={8}>
              <Switch style={{marginTop:'7px'}}
                    checkedChildren="Cita reservada" unCheckedChildren="Sin cita" 
                    checked={generate} onChange={() => {changeOptionsClientes()}}/>
            </Col>  
          </Row>
          <Row>
            <Col span={24}>
            <Select placeholder="Seleccione opción" onChange={setOption} 
              value={option}
              options={optionsClientes}
              style={{width:'100%',marginTop:'10px'}}>
            </Select>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextArea rows={4} style={{marginTop:'10px'}}
                value={notas} onChange={(t) => {console.log(t);setNotas(t.target.value)}}
                placeholder="Notas" />
            </Col>
          </Row>
          {errorOption && <Alert
            message="Debe seleccionar una opción"
            type="error"
          />}
        </Col>
      </Row>
    </Modal>

    <Modal
      title="Notas"
      open={state.openNotesModalState}
      onCancel={handleCloseModalState}
      footer={[
        <Button onClick={handleCloseModalState}>
          Cancelar
        </Button>,
        <Button type="primary" onClick={() => changeNotesWithPhone(state.selectedRow)}>
          Aceptar
        </Button>,
      ]}
    >
      <Row>
         <Col span={24}>
              Modifique las notas para la llamada 
          </Col>
          <Col span={24}>
            <TextArea rows={4} style={{marginTop:'10px'}}
              value={notas} onChange={(t) => {setNotas(t.target.value)}}
              placeholder="Notas" />
          </Col>
      </Row>
    </Modal>
  </ConfigProvider>
)
}